/* eslint-disable no-unused-vars */
import React from 'react';
import {
  Card, Paper, Zoom,
} from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { useTheme } from '@mui/material/styles';
import LogoImage from './LogoImage';
import image from '../resources/images/login.jpg';
import AuthFooter from './AuthFooter';
import Language from './Language';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
  },
  card: {
    width: theme.dimensions.sidebarWidth,
    [theme.breakpoints.down('md')]: {
      width: '94%',
    },
    borderRadius: 12,
    margin: '0 auto',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    maxWidth: 320,
    minWidth: 300,
    backgroundColor: '#000000b3',
  },
  sidebar: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.colors.primary,
    padding: theme.spacing(3),
    width: '100%',
    maxWidth: 320,
  },
  paper: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    flex: 1,
    boxShadow: '-2px 0px 16px rgba(0, 0, 0, 0.25)',
    [theme.breakpoints.up('lg')]: {
      padding: theme.spacing(0, 0, 0, 0),
    },
  },
  form: {
    maxWidth: theme.spacing(52),
    padding: theme.spacing(2),
    width: '100%',
  },
}));

const LoginLayout = ({ children }) => {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <main className={classes.root}>
      <Language />
      <Paper
        className={classes.paper}
        style={{
          backgroundImage: `url(${image})`,
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
        }}
      >
        <Zoom
          in
          style={{ transitionDelay: '200ms' }}
        >
          <Card className={classes.card} elevation={24}>
            <div className={classes.sidebar}>
              <LogoImage />
            </div>
            <form className={classes.form}>{children}</form>
          </Card>
        </Zoom>

      </Paper>
      <AuthFooter />
    </main>
  );
};

export default LoginLayout;

/*
<div class="half">
<div class="form-group">
<label for="registerEmail" i18n="Email" lang="en_US">Email</label>
<input type="email" class="form-control" id="registerEmail" i18n-placeholder="Email" placeholderlang="en_US" placeholder="Email">
</div>
<div class="form-group">
<label for="registerPassword" i18n="Password" lang="en_US">Password</label>
<input type="password" class="form-control" id="registerPassword" i18n-placeholder="Password" placeholderlang="en_US" placeholder="Password">
</div>
<div class="form-group">
<label for="registerRepeatPassword" i18n="RepeatPassword" lang="en_US">Repeat Password</label>
<input type="password" class="form-control" id="registerRepeatPassword" i18n-placeholder="RepeatPassword" placeholderlang="en_US" placeholder="Repeat Password">
</div>
<div class="form-group">
<label for="registerActivationCode" i18n="ActivationCode" lang="en_US">Activation Code</label>
<input type="text" class="form-control" id="registerActivationCode" i18n-placeholder="ActivationCode" placeholderlang="en_US" placeholder="Activation Code">
</div>
<button class="registerCancelBtn themeBtn" type="submit">
<span i18n="Cancel" lang="en_US">Cancel</span>
</button>
<button class="registerBtn themeBtn" type="submit">
<span style="display:none" class="loading iconfont icon-loading"></span><span i18n="Register" style="margin-left: 5px;" lang="en_US">Register</span>
</button>
</div>
 */
